import { IHorizontalBarChart } from "models/Interfaces/ICharts";
import "./Style.css";

function HorizontalBarChart({
  items,
  benchmarks,
  groupBenchmarks,
}: IHorizontalBarChart) {
  return (
    <div className="hosizontalBarContainer">
      <div className="legend-lines">
        {[...Array(10)].map((_, i) => (
          <div key={i} className="legend-line" />
        ))}
      </div>
      {items.map((item: any, index: number) => {
        const benchmark = benchmarks?.find(
          (x) => x.questionId === item.questionId,
        );
        const groupBenchmark = groupBenchmarks?.find(
          (x) => x.questionId === item.questionId,
        );
        return (
          <>
            <div key={index} className="hosizontalBarItem">
              <div className="hosizontalBarQuestionAndPercentages">
                <div className="hosizontalBarQuestion">{item.question}</div>
                <div className="hosizontalBarPercentages">
                  {item.negativePercentage > 0 && (
                    <div
                      className="negativeResponses responseSegment tooltip"
                      style={{ width: `${item.negativePercentage}%` }}
                    >
                      <span className="tooltiptext">{`${item.negativePercentage}%`}</span>
                    </div>
                  )}
                  {item.neutralPercentage > 0 && (
                    <div
                      className="neutralResponses responseSegment tooltip"
                      style={{ width: `${item.neutralPercentage}%` }}
                    >
                      <span className="tooltiptext">{`${item.neutralPercentage}%`}</span>
                    </div>
                  )}
                  {item.positivePercentage > 0 && (
                    <div
                      className="positiveResponses responseSegment tooltip"
                      style={{ width: `${item.positivePercentage}%` }}
                    >
                      <span className="tooltiptext">{`${item.positivePercentage}%`}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {benchmarks != null && benchmarks.length > 0 && (
              <div
                key={index + benchmarks.length}
                className="hosizontalBarItem"
              >
                <div className="benchmarkBarQuestionAndPercentages">
                  <div className="hosizontalBarQuestion" />
                  <div className="hosizontalBarPercentages opaqueBar">
                    {benchmark.negativePercentage > 0 && (
                      <div
                        className="negativeResponses responseSegment tooltip negative-opaque"
                        style={{ width: `${benchmark.negativePercentage}%` }}
                      >
                        <span className="tooltiptext">{`${benchmark.negativePercentage}%`}</span>
                      </div>
                    )}
                    {benchmark.neutralPercentage > 0 && (
                      <div
                        className="neutralResponses responseSegment tooltip neutral-opaque"
                        style={{ width: `${benchmark.neutralPercentage}%` }}
                      >
                        <span className="tooltiptext">{`${benchmark.neutralPercentage}%`}</span>
                      </div>
                    )}
                    {benchmark.positivePercentage > 0 && (
                      <div
                        className="positiveResponses responseSegment tooltip positive-opaque"
                        style={{ width: `${benchmark.positivePercentage}%` }}
                      >
                        <span className="tooltiptext">{`${benchmark.positivePercentage}%`}</span>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className="benchmarkText"
                  style={{
                    position: "absolute",
                    zIndex: "100",
                    right: "0",
                    color: "white",
                    fontStyle: "bold",
                    paddingRight: "10px",
                  }}
                >
                  {benchmark.surveyCycleName}
                </div>
              </div>
            )}
            {groupBenchmarks != null && groupBenchmarks.length > 0 && (
              <div
                key={index + groupBenchmarks.length}
                className="hosizontalBarItem"
              >
                <div className="benchmarkBarQuestionAndPercentages">
                  <div className="hosizontalBarQuestion" />
                  <div className="hosizontalBarPercentages opaqueBar">
                    {groupBenchmark.negativePercentage > 0 && (
                      <div
                        className="negativeResponses responseSegment tooltip negative-opaque"
                        style={{
                          width: `${groupBenchmark.negativePercentage}%`,
                        }}
                      >
                        <span className="tooltiptext">{`${groupBenchmark.negativePercentage}%`}</span>
                      </div>
                    )}
                    {groupBenchmark.neutralPercentage > 0 && (
                      <div
                        className="neutralResponses responseSegment tooltip neutral-opaque"
                        style={{
                          width: `${groupBenchmark.neutralPercentage}%`,
                        }}
                      >
                        <span className="tooltiptext">{`${groupBenchmark.neutralPercentage}%`}</span>
                      </div>
                    )}
                    {groupBenchmark.positivePercentage > 0 && (
                      <div
                        className="positiveResponses responseSegment tooltip positive-opaque"
                        style={{
                          width: `${groupBenchmark.positivePercentage}%`,
                        }}
                      >
                        <span className="tooltiptext">{`${groupBenchmark.positivePercentage}%`}</span>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className="benchmarkText"
                  style={{
                    position: "absolute",
                    zIndex: "100",
                    right: "0",
                    color: "white",
                    fontStyle: "bold",
                    paddingRight: "10px",
                  }}
                >
                  {groupBenchmark.surveyCycleName}
                </div>
              </div>
            )}
          </>
        );
      })}
      <div className="legend-percentages">
        {[...Array(11)].map((_, i) => (
          <div key={i} className="legend-percentage">
            {i * 10}%
          </div>
        ))}
      </div>
    </div>
  );
}

export default HorizontalBarChart;
