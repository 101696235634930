import { ReportingTypeEnum } from "models/Enum/EnumTypes";
import { ICompareBarChart } from "models/Interfaces/ICharts";
import "./Style.css";

function CompareBarChart({
  responses,
  tab,
  benchmarks,
  groupBenchmarks,
}: ICompareBarChart) {
  return (
    <div className="compareBarContainer">
      <div className="legend-lines">
        {[...Array(10)].map((_, i) => (
          <div key={i} className="legend-line" />
        ))}
      </div>
      {responses.map((item, index) => {
        const benchmark = benchmarks?.find(
          (x) => x.questionId === item.questionId,
        );
        const groupBenchmark = groupBenchmarks?.find(
          (x) => x.questionId === item.questionId,
        );
        return (
          <>
            <div key={index} className="compareBarItem">
              <div className="compareBarQuestionAndPercentages">
                <div className="compareBarQuestionText">{item.description}</div>
                <div className="compareBarQuestionPercentages">
                  {item.responses.map((response: any, subIndex: number) => (
                    <div
                      key={subIndex}
                      className={`compareBarItemPercentages ${
                        subIndex === 1 ? "opaqueBar" : ""
                      }`}
                    >
                      {tab === ReportingTypeEnum.Result &&
                      response.noQuestionMessage &&
                      response.noQuestionMessage !== "" ? (
                        <p>{response.noQuestionMessage}</p>
                      ) : tab === ReportingTypeEnum.Priorities &&
                        response.noPriorityMessage &&
                        response.noPriorityMessage !== "" ? (
                        <p>{response.noPriorityMessage}</p>
                      ) : (
                        <>
                          {response.negativePercentage > 0 && (
                            <div
                              className={`negativeResponses responseSegment tooltip ${
                                subIndex === 1 ? "negative-opaque" : ""
                              }`}
                              style={{
                                width: `${response.negativePercentage}%`,
                              }}
                            >
                              <span className="tooltiptext">{`${response.negativePercentage}%`}</span>
                            </div>
                          )}
                          {response.neutralPercentage > 0 && (
                            <div
                              className={`neutralResponses responseSegment tooltip ${
                                subIndex === 1 ? "neutral-opaque" : ""
                              }`}
                              style={{
                                width: `${response.neutralPercentage}%`,
                              }}
                            >
                              <span className="tooltiptext">{`${response.neutralPercentage}%`}</span>
                            </div>
                          )}
                          {response.positivePercentage > 0 && (
                            <div
                              className={`positiveResponses responseSegment tooltip ${
                                subIndex === 1 ? "positive-opaque" : ""
                              }`}
                              style={{
                                width: `${response.positivePercentage}%`,
                              }}
                            >
                              <span className="tooltiptext">{`${response.positivePercentage}%`}</span>
                            </div>
                          )}
                        </>
                      )}
                      {subIndex === 1 && (
                        <div
                          className="benchmarkText"
                          style={{
                            position: "absolute",
                            zIndex: "100",
                            right: "0",
                            color: "white",
                            fontStyle: "bold",
                            paddingRight: "10px",
                          }}
                        >
                          {response.surveyCycleName}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {benchmarks != null && benchmarks.length > 0 && (
              <div
                key={index + benchmarks.length}
                className="hosizontalBarItem"
              >
                <div className="benchmarkBarQuestionAndPercentages">
                  <div className="hosizontalBarQuestion" />
                  <div className="hosizontalBarPercentages opaqueBar">
                    {benchmark.negativePercentage > 0 && (
                      <div
                        className="negativeResponses responseSegment tooltip negative-opaque"
                        style={{ width: `${benchmark.negativePercentage}%` }}
                      >
                        <span className="tooltiptext">{`${benchmark.negativePercentage}%`}</span>
                      </div>
                    )}
                    {benchmark.neutralPercentage > 0 && (
                      <div
                        className="neutralResponses responseSegment tooltip neutral-opaque"
                        style={{ width: `${benchmark.neutralPercentage}%` }}
                      >
                        <span className="tooltiptext">{`${benchmark.neutralPercentage}%`}</span>
                      </div>
                    )}
                    {benchmark.positivePercentage > 0 && (
                      <div
                        className="positiveResponses responseSegment tooltip positive-opaque"
                        style={{ width: `${benchmark.positivePercentage}%` }}
                      >
                        <span className="tooltiptext">{`${benchmark.positivePercentage}%`}</span>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className="benchmarkText"
                  style={{
                    position: "absolute",
                    zIndex: "100",
                    right: "0",
                    color: "white",
                    fontStyle: "bold",
                    paddingRight: "10px",
                  }}
                >
                  {benchmark.surveyCycleName}
                </div>
              </div>
            )}
            {groupBenchmarks != null && groupBenchmarks.length > 0 && (
              <div
                key={index + groupBenchmarks.length}
                className="hosizontalBarItem"
              >
                <div className="benchmarkBarQuestionAndPercentages">
                  <div className="hosizontalBarQuestion" />
                  <div className="hosizontalBarPercentages opaqueBar">
                    {groupBenchmark.negativePercentage > 0 && (
                      <div
                        className="negativeResponses responseSegment tooltip negative-opaque"
                        style={{
                          width: `${groupBenchmark.negativePercentage}%`,
                        }}
                      >
                        <span className="tooltiptext">{`${groupBenchmark.negativePercentage}%`}</span>
                      </div>
                    )}
                    {groupBenchmark.neutralPercentage > 0 && (
                      <div
                        className="neutralResponses responseSegment tooltip neutral-opaque"
                        style={{
                          width: `${groupBenchmark.neutralPercentage}%`,
                        }}
                      >
                        <span className="tooltiptext">{`${groupBenchmark.neutralPercentage}%`}</span>
                      </div>
                    )}
                    {groupBenchmark.positivePercentage > 0 && (
                      <div
                        className="positiveResponses responseSegment tooltip positive-opaque"
                        style={{
                          width: `${groupBenchmark.positivePercentage}%`,
                        }}
                      >
                        <span className="tooltiptext">{`${groupBenchmark.positivePercentage}%`}</span>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className="benchmarkText"
                  style={{
                    position: "absolute",
                    zIndex: "100",
                    right: "0",
                    color: "white",
                    fontStyle: "bold",
                    paddingRight: "10px",
                  }}
                >
                  {groupBenchmark.surveyCycleName}
                </div>
              </div>
            )}
          </>
        );
      })}
      <div className="legend-percentages">
        {[...Array(11)].map((_, i) => (
          <div key={i} className="legend-percentage">
            {i * 10}%
          </div>
        ))}
      </div>
    </div>
  );
}

export default CompareBarChart;
