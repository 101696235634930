import { SmallSpinner } from "components/Spinner";
import "./Style.css";
import CheckBox from "components/Controls/CheckBox";
import Controls from "components/Controls";

function BenchmarkBox(params: any) {
  return (
    <div className="benchmarkBox">
      {params.avgParticipation == null ||
      params.avgSiteScore == null ||
      (params.benchmarkParticipation == null && params.groupScore > 0) ||
      params.groupScore == null ? (
        <div className="spinnerContainer">
          <SmallSpinner />
        </div>
      ) : (
        <>
          <div className={`benchmarkBoxBanner`}>
            <div>
              <p style={{ display: "inline-block", paddingLeft: "5px" }}>
                Your average site score
              </p>
            </div>
            <div
              className={`avgSiteScoreCircle ${
                params.avgSiteScore < 0
                  ? "scoreZero"
                  : params.avgSiteScore >= 0 && params.avgSiteScore <= 5
                  ? "scoreLow"
                  : params.avgSiteScore > 5 && params.avgSiteScore <= 7.5
                  ? "scoreMedium"
                  : "scoreHigh"
              }`}
            >
              {params.avgSiteScore < 0 ? "N/A" : params.avgSiteScore}
            </div>
          </div>
          <div className={`benchmarkBoxBanner`}>
            <div>
              <p style={{ display: "inline-block", paddingLeft: "5px" }}>
                Your average participation rate is{" "}
                {`${params.avgParticipation}%`}.
              </p>
            </div>
          </div>
          <div className={`benchmarkBoxBanner`}>
            <div>
              <CheckBox
                id="enableBenchmarks"
                label="View your averages?"
                name="enableBenchmarks"
                value={params.benchmarksEnabled}
                onChange={params.toggleBenchmarks}
                error={""}
                checked={params.benchmarksEnabled}
              />
            </div>
          </div>
          {params.groups.length > 0 && (
            <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
              <Controls.AutoComplete
                id="surveyTemplateTypeId"
                name="surveyTemplateTypeId"
                label="Select a group*"
                value={params.selectedGroupId}
                inputValue={params.selectedGroupName}
                onChange={params.handleSelectGroup}
                onInputChange={params.handleSelectGroupTextInputChange}
                options={params.groups}
              />
            </div>
          )}
          {params.groupScore && params.groupScore > 0 && (
            <div className={`benchmarkBoxBanner`}>
              <div>
                <p style={{ display: "inline-block", paddingLeft: "5px" }}>
                  Benchmark site score
                </p>
              </div>
              <div
                className={`avgSiteScoreCircle ${
                  params.groupScore < 0
                    ? "scoreZero"
                    : params.groupScore >= 0 && params.groupScore <= 5
                    ? "scoreLow"
                    : params.groupScore > 5 && params.groupScore <= 7.5
                    ? "scoreMedium"
                    : "scoreHigh"
                }`}
              >
                {params.groupScore < 0 ? "N/A" : params.groupScore}
              </div>
            </div>
          )}
          {params.benchmarkParticipation && (
            <div className={`benchmarkBoxBanner`}>
              <div>
                <p style={{ display: "inline-block", paddingLeft: "5px" }}>
                  Benchmark participation rate is{" "}
                  {`${params.benchmarkParticipation}%`}.
                </p>
              </div>
            </div>
          )}
          {params.groupScore && params.groupScore > 0 && (
            <div className={`benchmarkBoxBanner`}>
              <div>
                <CheckBox
                  id="enableBenchmarks"
                  label="View benchmarks?"
                  name="enableBenchmarks"
                  value={params.groupBenchmarksEnabled}
                  onChange={params.toggleGroupBenchmarks}
                  error={""}
                  checked={params.groupBenchmarksEnabled}
                />
              </div>
            </div>
          )}
          {params.selectedGroupId !== null &&
            params.selectedGroupId !== "" &&
            params.groupScore < 0 && (
              <div className={`benchmarkBoxBanner`}>
                <div>
                  <p style={{ display: "inline-block", paddingLeft: "5px" }}>
                    There are no benchmarks for the currently selected group and
                    survey template type.
                  </p>
                </div>
              </div>
            )}
        </>
      )}
    </div>
  );
}

export default BenchmarkBox;
